import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  sectionPaddings,
  brandColours,
  fontSize,
  headingStyles,
} from '../styles';
import { Container, Button } from './ui';

const StyledNotFoundMessage = styled.section`
  ${sectionMargins('40px', '120px')};
`;

const StyledInner = styled.div`
  position: relative;
  ${sectionPaddings('40px', '120px')};
  text-align: center;

  &:before {
    content: '404';
    position: absolute;
    top: 50%;
    left: 50%;
    color: ${brandColours.denary};
    ${headingStyles('150px', '600px')};
    opacity: 0.1;
    transform: translate(-50%, -50%);
  }
`;

const StyledHeading = styled.h1`
  ${headingStyles('36px', '80px')};
`;

const StyledText = styled.p`
  margin: 18px 0;

  ${minBreakpointQuery.small`
    margin-top: 22px;
    margin-bottom: 22px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 26px;
    margin-bottom: 26px;
    ${fontSize(20)};
  `}
`;

const NotFoundMessage = () => (
  <StyledNotFoundMessage>
    <Container>
      <StyledInner>
        <StyledHeading>Page not found</StyledHeading>
        <StyledText>It looks like this page is no longer available.</StyledText>
        <Button to="/" arrow={true}>
          Return home
        </Button>
      </StyledInner>
    </Container>
  </StyledNotFoundMessage>
);

export default NotFoundMessage;
