import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import NotFoundMessage from '../components/NotFoundMessage';

const NotFoundPage = () => {
  const {
    site: {
      globalSeo: { titleSuffix },
    },
  } = useStaticQuery(graphql`
    query NotFoundPageQuery {
      site: datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
    }
  `);

  return (
    <Layout locale="en">
      <HelmetDatoCms
        title="404, Page Not Found"
        titleTemplate={`%s${titleSuffix}`}
      />
      <main>
        <NotFoundMessage />
      </main>
    </Layout>
  );
};

export default NotFoundPage;
